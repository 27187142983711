
import { Options, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  private activeIndex: any = "专业律师团队";
  private activeIndex2 = "1";
  private show = false;
  private show1 = false;
  private show2 = false;
  private clickOption(i: number) {
    this.activeIndex = i;
  }
  mouseoverOption(i: any) {
    this.activeIndex = i;
  }

  dataProps = {
    name: "安心四大优势",
    letter: "FOUR ADVANTAGES ABOUT US",
    content: "有爱有温度，让您更心安",
    type: true,
  };
  liDta = [
    {
      img: require("@/assets/img/page5-1.png"),
      icon: require("@/assets/img/page5-icon-1.png"),
      iconHover: require("@/assets/img/page5-icon-hover (3).png"),
      text: "10 年以上执业经验团队，多年办案经验，为客户精准分析婚姻家庭案件关键争议点，提供最佳调解和诉讼方案",
      name: "专业律师团队",
    },
    {
      img: require("@/assets/img/page5-2.png"),
      icon: require("@/assets/img/page5-icon-2.png"),
      iconHover: require("@/assets/img/page5-icon-hover (2).png"),
      text: "融入互联网科技力量，让客户对自己的案件流程及推进情况了若指掌。",
      name: "可视化办案流程",
    },
    {
      img: require("@/assets/img/page5-3.png"),
      icon: require("@/assets/img/page5-icon-3.png"),
      iconHover: require("@/assets/img/page5-icon-hover (4).png"),
      text: "收费标准透明、合理，充分实现客户对收费事项的知悉权，分阶段支付，解除您的付费顾虑。",
      name: "全透明收费标准",
    },
    {
      img: require("@/assets/img/page5-4.png"),
      icon: require("@/assets/img/page5-icon-4.png"),
      iconHover: require("@/assets/img/page5-icon-hover (1).png"),
      text: "一审首次起诉未能得到离婚判决或调解结果的，一审的再次起诉全流程不再收取代理费用，为您提供一站式全流程服务。",
      name: "一站式全流程服务",
    },
  ];
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
