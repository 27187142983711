
import { Options, prop, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
import { getSetting } from "@/api/index";

@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  // private getData = getData
  phone() {
    window.location.href = "tel:" + "18600105596";
  }

  private jingyun() {
    // window.location.target = "_blank";
    // window.location.href = "http://www.jinglaw.cn/";
    window.open("http://www.jinglaw.cn/");
  }

  private dateil: any = {};
  private async getSet() {
    let { data }: any = await getSetting();
    this.dateil = data;
  }

  mounted() {
    // this.getSet();
  }
}
