import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ad8c40b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page3" }
const _hoisted_2 = { class: "haeder" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "left" }
const _hoisted_7 = { class: "time" }
const _hoisted_8 = { class: "year" }
const _hoisted_9 = { class: "right" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "text1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleCom = _resolveComponent("TitleCom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_TitleCom, { dataProps: _ctx.dataProps }, null, 8, ["dataProps"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.dataList && _ctx.dataList.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (t, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (_ctx.clickOption(t.id))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(t.publishTime.split("-")[1] + "." + t.publishTime.split("-")[2]), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(t.publishTime.split("-")[0]), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(t.title), 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(t.summary), 1)
                ])
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}