
import { Options, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  private activeIndex = 0;
  private activeIndex2 = "1";
  private liData: any = [
    "婚前个人贷款买的房，离婚时对方有权利分割吗？",
    "哪些证据是证明对方有婚外情的有力证据？",
    "没有遗嘱，私生子有权继承码？",
    "什么情况下，法院可以判决准予离婚？",
  ];

  private dataContent: any = [
    {
      title: "婚前个人贷款买的房，离婚时对方有权利分割吗？",
      content: [
        "咱们很多朋友会认为 这房子是我父母付的首付，贷款也是我个人还的，写的也是我个人的名字，这就属于我的婚前个人财产。将来两人要是不幸分道扬镳了，这房子也不会纳入婚后共同财产而进行分割的。",
        "这里律师提醒您：您的婚后还贷行为，即使是用自己的工资、住房公积金还贷，也属于用夫妻共同财产还贷，这期间还贷支付的款项及其对应的房产的增值部分，属于夫妻共同财产。如果双方离婚时对房产处理达不成一致意见，法院通常会把房子判给登记产权人，但是婚后还贷的款项以及对应的那部分房产的增值价款，是要作为夫妻共同财产与对方进行分割的，一般是以现金方式补偿给对方。",
      ],
    },
    {
      title: "哪些证据是证明对方有婚外情的有力证据？",
      content: [
        "首先，对方是否有婚外情，不能捕风捉影，需要有充分确凿的证据。如果只有通话记录、短消息内容、合影照片这些间接证据，证明力相对较小，法院也不会根据这些证据就认定对方存在婚外情。",
        "其次，要有合适的取证技巧。以下几类证据在证明对方婚外情方面是比较有利的（1）在发现对方有出轨行为时，要求对方手写“保证书”、“道歉信”等，为了维持婚姻关系，对方有可能愿意写下的表示悔改的书面证据；（2）对方有在酒店与第三者留宿、嫖娼事件的，建议您及时报警，由警方介入后，留取警方的笔录或对方被处罚的证据;（3）对方与第三者的情感沟通微信、短信、电子邮件等书面证据，或较为亲昵的照片、录像等视听资料。",
      ],
    },
    {
      title: "没有遗嘱，私生子有权继承码？",
      content: [
        "前几天，有位女士来咨询，父亲意外去世，全家都沉浸在悲痛之中。这时候呢，有一年轻女子带着个男孩来家里，说要分父亲的遗产。他们全家看见门口站着的这娘俩，都非常气愤，也不认可他们的身份，但年轻女子拿出了亲子鉴定报告，撂下一句话，法庭见！",
        "那么，被继承人生前没有留下遗嘱，私生子有继承权吗？根据我国《民法典》的规定，第一顺序继承人包括：配偶、子女、父母；同时，非婚生子女享有与婚生子女同等的权利，当然也包括继承权。所以这个小男孩将作为第一顺序继承人，有权与其他合法人共同继承他父亲的合法财产。",
      ],
    },
    {
      title: "什么情况下，法院可以判决准予离婚？",
      content: [
        "离婚诉讼中，有以下几种情形的，法院一般可以判离的，尤其是最后两种：",
        "（1）重婚或与他人同居;（2）实施家庭暴力或虐待、遗弃家庭成员的;（3）有赌博、吸毒等恶习屡教不改的;（4）因感情不和分居满二年的;（5）一方被宣告失踪，另一方提起离婚诉讼的；（6）法院判决不准离婚后分居满一年，一方再次提起离婚的。同时，如果庭审过程中，法官开始关注财产分割和孩子抚养权问题的细节，说明判决离婚的可能性大。",
      ],
    },
  ];
  private clickOption(i: number) {
    this.activeIndex = i;
  }
  dataProps = {
    name: "婚姻常识",
    letter: "MARRIAGE COMMON SENSE",
    content: "离婚纠纷的常见问题",
  };
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
