import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70aeddef"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  onclick: "window.open('http://p.qiao.baidu.com/cps/chat?siteId=15869372&userId=31350453&siteToken=730f6e8d0942a32570f76346adb1f82b&cp=&cr=www.jinglaw.cn&cw=')",
  class: "but"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, "立即在线咨询律师"))
}