
import { Options, prop, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  private dataContent: any = [
    {
      title: "创新",
      text1: "自助查询系统",
      text2: "随时随地查询案件处理进程",
      icon: require("@/assets/img/创新@2x.png"),
    },
    {
      title: "专业",
      text1: "成立专案小组",
      text2: "为客户量身定制解决方案",
      icon: require("@/assets/img/专业@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
  ];
  private activeIndex: any = 0;
  private clickOption(i: number) {
    this.activeIndex = i;
  }
  dataProps = {
    name: "五大品质理由",
    letter: "FIVE CHARACTERISTICS OF TEAM",
    content: "专业用心，只为给您一份安心",
  };
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
