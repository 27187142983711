import request from "@/utils/request";

// 文章列表
export const getArticleList = () => {
    return request({
        url: 'website/article/list',
        method: "get",
        params: {}
    })
}

// 文章详情
export const getArticleInfo = (id: string) => {
    return request({
        url: 'website/article/info',
        method: "get",
        params: {
            id: id
        }
    })
}

// 网站配置
export const getSetting = () => {
    return request({
        url: 'website/setting/list',
        method: "get",
        params: {}
    })
}

