
import { Options, Vue } from "vue-class-component";
import Header from "@/views/header.vue";
import Nav from "@/views/nav.vue"; // @ is an alias to /src
import Banner from "@/views/banner.vue"; // @ is an alias to /src
import Page1 from "@/views/page1.vue"; // @ is an alias to /src
import Page2 from "@/views/page2.vue"; // @ is an alias to /src
import Page3 from "@/views/page3.vue"; // @ is an alias to /src
import Page4 from "@/views/page4.vue"; // @ is an alias to /src
import Page5 from "@/views/page5.vue"; // @ is an alias to /src
import Page6 from "@/views/page6.vue"; // @ is an alias to /src
import Page7 from "@/views/page7.vue"; // @ is an alias to /src
import Page8 from "@/views/page8.vue"; // @ is an alias to /src
import Footer from "@/views/footer.vue"; // @ is an alias to /src

@Options({
  name:'Home',
  components: {
    Header,
    Nav,
    Banner,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Footer,
  },
})
export default class Home extends Vue {
  ondhl(isvalue: any, id: any) {
    let gd: any = document.getElementById(id);
    console.log(gd.offsetTop);
    window.scrollTo({ top: gd.offsetTop, behavior: "smooth" });
  }

  mounted() {
    if (this.$route.query.index) {
      let id: any = this.$route.query.index;
      let gd: any = document.getElementById(id);
      console.log(gd.offsetTop);
      console.log('0--------');
      window.scrollTo({ top: gd.offsetTop });
    }
  }
}
