
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    dataProps: Object,
  },
})
export default class HelloWorld extends Vue {
  // dataProps!: string;
}
