
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Home extends Vue {
  private activeIndex = "1";
  private activeIndex2 = "1";
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
