
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Home extends Vue {
  private activeIndex = "1";
  private activeIndex2 = "";
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }

  mounted() {
    if (this.$route.name == "dateil") {
      this.activeIndex2 = '';
    }else{
      this.activeIndex2 = '1';
    }
  }
}
