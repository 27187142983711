
import { Options, prop, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getArticleList } from "@/api/index";

@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  // private getData = getData
  private dataContent: any = [
    {
      title: "创新",
      text1: "自助查询系统",
      text2: "随时随地查询案件处理进程",
      icon: require("@/assets/img/创新@2x.png"),
    },
    {
      title: "专业",
      text1: "成立专案小组",
      text2: "为客户量身定制解决方案",
      icon: require("@/assets/img/专业@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
    {
      title: "真诚",
      text1: "收费标准透明",
      text2: "消费分阶段，不花冤枉钱",
      icon: require("@/assets/img/真诚@2x.png"),
    },
  ];
  private activeIndex: any = "";
  private clickOption(itemId: any) {
    let params = { id: itemId };
    console.log(params);
    this.$router.push({
      path: "/dateil",
      query: params,
    });
  }

  private dataList: any = "";

  private async getList() {
    let { data }: any = await getArticleList();
    if (data && data.length) {
      let dataList = data.map((t: any, i: number) => {
        if (i < 4) {
          return t;
        }
      });
      this.dataList = dataList;
    }
  }

  mounted() {
    this.getList();
  }

  dataProps = {
    name: "安心动态",
    letter: "NEWS DEVELOPMENTS",
    content: "您的满意，我们的动力",
  };
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
