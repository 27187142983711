
import { Options, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  private activeIndex = 0;
  private activeIndex2 = "1";
  private show = false;
  private show1 = false;
  private show2 = false;
  private clickOption(i: number) {
    this.activeIndex = i;
  }
  private mouseoverOption1() {
    this.show = true;
    this.show1 = false;
    this.show2 = false;
  }
  private mouseoverOption2() {
    this.show = false;
    this.show1 = true;
    this.show2 = false;
  }
  private mouseoverOption3() {
    this.show = false;
    this.show1 = false;
    this.show2 = true;
  }
  private mouseoutOption() {
    this.show = false;
    this.show1 = false;
    this.show2 = false;
  }
  dataProps = {
    name: "遗产继承",
    letter: "SUCCESSION TO AN ESTATE",
    content: "保住你的应得财产",
  };
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
