
import { Options, Vue } from "vue-class-component";
import But from "@/components/btn.vue";
import TitleCom from "@/components/titleCom.vue";
@Options({
  components: { But, TitleCom },
})
export default class Home extends Vue {
  private activeIndex = "1";
  private activeIndex2 = "1";
  dataProps = {
    name: "离婚程序",
    letter: "DIVORCE PROCEEDING",
    content: "两种程序，应该如何选择？",
  };
  private handleSelect(key: any, keyPath: any) {
    console.log(key, keyPath);
  }
}
